@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Aljazeera;
  src: url(./assets/fonts/Aljazeera-Regular.ttf);
}
@font-face {
  font-family: HighlandGoth;
  src: url(./assets/fonts/HighlandGothicFLF.ttf);
}

:root {
  --main-color: '';
  --opacity-color: rgba(100 197 177 / 40%);
  --En-font: 'Montserrat';
  --Ar-font: 'Aljazeera';
  --general-font: 'HighlandGoth';
  --main-font: 'Montserrat';
  --background: '';
  --first-header-color: rgba(0 0 0 0.8);
  --second-header-color: rgba(0 0 0 0);

  --first-footer-color: rgba(0 0 0 0.8);
  --second-footer-color: rgba(0 0 0 0);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--En-font);
}

html,
body {
  width: 100vw;
  max-width: 2000px;
  margin: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.scroll::-webkit-scrollbar {
  width: 3px;
}

.scroll1::-webkit-scrollbar {
  width: 0px;
}

.icon,
svg,
path,
svg path {
  fill: var(--main-color) !important;
  color: var(--main-color) !important;
}

.header-modal {
  left: auto;
  right: auto;
}

@media screen and (max-width: 670px) {
  .header-modal {
    left: 0;
    right: 0;
  }
}

header {
  width: 100%;
  overflow-x: hidden;

  /* height: 750px; */
  height: 100vh;
  min-height: 700px;
  background-color: #1a1a1a;
  position: relative;
  max-width: 2000px;
}
.back-img {
  position: absolute;
  width: 100vw;
  min-height: 700px;
  /* height: 750px; */
  height: 100vh;
  max-width: 2000px;

  background: var(--background);
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  transition: 2.5s;
  filter: brightness(20%);
}
@media screen and (max-width: 900px) {
  .back-img {
    background-position: top center !important;
  }
}
@media screen and (max-width: 1050px) {
  .back-img,
  header {
    height: 800px;
  }
}
@media screen and (max-width: 670px) {
  .back-img,
  header {
    height: 900px;
  }
}

textarea:read-only {
  background: #f1f4f5;
  border: none !important;
}
textarea{
  min-width: 100%;
  max-width: 100%;
}

.list .card:nth-child(odd) {
  border-left: none;
}

.list .card:nth-child(even) {
  border-right: none;
  border-left: none;
}
.fade-in {
  opacity: 0;
  animation: fade-in 2s forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.scale {
  transform-origin: top;
  transform: scaleY(0);
  animation: scale 1s forwards;
  animation-delay: 1s;
}

@keyframes scale {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes height {
  0% {
    height: 0%;
  }
  100% {
    height: 100vh;
  }
}

@keyframes heightMedium {
  0% {
    height: 0%;
  }
  100% {
    height: 700px;
  }
}

@keyframes height2 {
  0% {
    height: 0%;
  }
  100% {
    height: 800px;
  }
}

@keyframes height3 {
  0% {
    height: 0%;
  }
  100% {
    height: 835px;
  }
}
